import axios from "axios";
import queryString from "query-string";

const axiosClient = axios.create({
	baseURL: process.env.REACT_APP_API_PG,
	headers: {
		"Content-Type": "application/json",
	},
	paramsSerializer: (params) => queryString.stringify(params),
});

axios.defaults.baseURL = process.env.REACT_APP_API;

axiosClient.interceptors.request.use(async (config) => {
	const employeeInfo = JSON.parse(localStorage.getItem("employeeInfo"));

	if (!!employeeInfo) {
		config.headers.Authorization = `Bearer ${employeeInfo.token}`;
	}

	return config;
});

axiosClient.interceptors.response.use(
	(response) => {
		if (response && response.data) {
			return response.data;
		}

		return response;
	},
	(error) => {
		// Handle errors
		// console.log(error.response.data);
		throw error.response.data;
	}
);

export default axiosClient;
