import authAPI from "api/AuthAPI";
import { LOGIN_EMPLOYEE, LOGOUT_EMPLOYEE } from "./type";

export const logout = (onSuccess = (success = false) => {}) => {
	return async (dispatch) => {
		try {
			localStorage.clear();
			dispatch({
				type: LOGOUT_EMPLOYEE,
			});

			onSuccess(true);
		} catch (err) {
			onSuccess(false);
			console.log(err);
		}
	};
};

export const loginEmployee = (
	data,
	onSuccess = (success = false) => {},
	onError = (error = "") => {}
) => {
	return async (dispatch, getState) => {
		try {
			const res = await authAPI.loginCMS(data);
			dispatch({
				type: LOGIN_EMPLOYEE,
				payload: res?.results,
			});

			const employeeInfo = res?.results;
			localStorage.setItem("employeeInfo", JSON.stringify(employeeInfo));
			onSuccess(true);
		} catch (err) {
			onSuccess(false);
			onError(err);
		}
	};
};
