import { Button } from "@mui/material";
import { SnackbarProvider } from "notistack";
import React, { useRef } from "react";

const ToastProvider = (props) => {
	const notistackRef = useRef();

	return (
		<SnackbarProvider
			maxSnack={1}
			anchorOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			ref={notistackRef}
			action={(key) => (
				<Button
					onClick={() => notistackRef.current.closeSnackbar(key)}
					style={{ color: "#fff", fontSize: "20px" }}
				>
					✖
				</Button>
			)}
			classes={{
				containerRoot: {
					zIndex: "100000",
				},
				root: {
					zIndex: "100000",
				},
			}}
		>
			{props.children}
		</SnackbarProvider>
	);
};

export { ToastProvider };
