import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Pagination,
	Snackbar,
	TextField,
	Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Modal, Tooltip, Upload } from "antd";
import bookAPI from "api/BookAPI";
import fileAPI from "api/FileAPI";
import unitAPI from "api/UnitAPI";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Document, Page, pdfjs } from "react-pdf";
import { useParams } from "react-router";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import ModalEmpty from "ui-component/modal-form/modal-empty";
import {
	unit,
	unitY6_TT_B1_B2,
	unitY8_TT_B1_B2,
	Y6_TT_RC_B1_ID,
	Y6_TT_RC_B2_ID,
	Y8_TT_RC_B1_ID,
	Y8_TT_RC_B2_ID,
} from "utils";

const Root = styled("div")`
	table {
		font-family: arial, sans-serif;
		border-collapse: collapse;
		width: 100%;
	}

	td,
	th {
		border: 1px solid #ddd;
		text-align: left;
		padding: 8px;
	}

	th {
		background-color: #red;
	}
`;

const EditUnitTT = () => {
	const { book_id } = useParams();
	const { enqueueSnackbar } = useSnackbar();

	const { register, handleSubmit, watch, setValue, formState } = useForm();
	const { errors } = formState;
	watch();
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

	const [rows, setRows] = useState([]);
	const [book, setBook] = useState({});
	const [isUpdate, setIsUpdate] = useState(false);
	const [currentUnit, setCurrentUnit] = useState({});
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState({
		id: "",
		open: false,
	});
	const [openDeleteConfirmFile, setOpenDeleteConfirmFile] = useState({
		id: "",
		open: false,
	});
	const [isUploadList, setIsUploadList] = useState([]);
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [currentUrl, setCurrentUrl] = useState("");
	const [openModalPDF, setOpenModalPDF] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);

	const columns = [
		{
			field: "order",
			headerName: "Order",
			editable: false,
			sortable: true,
			headerAlign: "center",
			align: "center",
			minWidth: 150,
		},
		{
			field: "title",
			headerName: "Title",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			flex: 1,
		},
		{
			field: "book_url",
			headerName: "PDF File",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			minWidth: 200,
			renderCell: (params) => {
				return (
					<>
						{handleLoading(params?.id) ? (
							<CircularProgress size={30} />
						) : (
							params?.value && (
								<>
									<Button
										sx={{
											mr: 2,
										}}
										variant="outlined"
										onClick={() => openPDF(params?.value)}
									>
										View file
									</Button>
									<Button
										variant="outlined"
										onClick={() => {
											setOpenDeleteConfirmFile({ id: params?.id, open: true });
										}}
									>
										Remove file
									</Button>
								</>
							)
						)}
					</>
				);
			},
		},
		{
			field: "actions_file",
			headerName: "Action",
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
			minWidth: 250,
			renderCell: (params) => {
				return (
					<>
						{params?.row?.title ? (
							<Upload
								name="file"
								showUploadList={false}
								beforeUpload={beforeUpload}
								customRequest={handleUploadImage}
								disabled={handleLoading(params?.id)}
							>
								<Button
									variant="outlined"
									disabled={handleLoading(params?.id)}
									onClick={() => setSelectedFile(params?.row)}
								>
									{params?.row?.book_url ? "Change File" : "Upload File"}
								</Button>
							</Upload>
						) : null}
					</>
				);
			},
		},
		{
			field: "actions",
			headerName: "Action",
			editable: true,
			sortable: false,
			headerAlign: "center",
			// align: "center",
			minWidth: 100,
			renderCell: (params) => (
				<>
					<>
						<Tooltip title="Edit">
							<IconButton aria-label="edit">
								<EditIcon onClick={() => handleOpenEditForm(params.row)} />
							</IconButton>
						</Tooltip>

						{params.row?.title && (
							<Tooltip title="Delete">
								<IconButton aria-label="delete">
									<DeleteIcon onClick={() => setOpenDeleteConfirm({ id: params.id, open: true })} />
								</IconButton>
							</Tooltip>
						)}
					</>
				</>
			),
		},
	];

	const getBook = async () => {
		try {
			const res = await bookAPI.findOne(book_id, {
				fields: '["$all", {"unit": ["$all"]}]',
			});

			let unitRaw = unit;

			const book = res.results.object;
			setBook(book);
			const unitList = res.results.object?.unit;
			if (book?.id === Y6_TT_RC_B1_ID || book?.id === Y6_TT_RC_B2_ID) {
				unitRaw = unitY6_TT_B1_B2;
			}
			if (book?.id === Y8_TT_RC_B1_ID || book?.id === Y8_TT_RC_B2_ID) {
				unitRaw = unitY8_TT_B1_B2;
			}

			const newUnit = unitRaw?.map((item) => {
				for (const unitValue of unitList) {
					if (unitValue.order === item.order) {
						return {
							...unitValue,
						};
					}
				}
				return item;
			});
			setRows(newUnit);
		} catch (err) {
			console.log("err: ", err);
		}
	};

	const removeUnit = async () => {
		try {
			await unitAPI.remove(openDeleteConfirm.id);
			enqueueSnackbar("Success", {
				variant: "success",
				autoHideDuration: 2000,
			});
			getBook();
		} catch (err) {
			enqueueSnackbar(err?.message || "Error", {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
		setOpenDeleteConfirm({ id: "", open: false });
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const handleLoading = (id) => {
		const isCurrentArray = isUploadList?.filter((item) => item?.id === id);
		return isCurrentArray?.length > 0;
	};

	const handleOpenEditForm = async (unit) => {
		await setCurrentUrl(unit.book_url);
		await setCurrentUnit(unit);
		await setOpenModal(true);
		unit?.title ? setIsUpdate(true) : setIsUpdate(false);

		setValue("title", unit?.title, {
			shouldValidate: false,
			shouldDirty: true,
		});
	};

	function beforeUpload(file) {
		const isPDF = file.type === "application/pdf";

		if (!isPDF) {
			enqueueSnackbar("Please only upload PDF files" || "Error", {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
		return isPDF;
	}

	const openPDF = (url) => {
		setPageNumber(1);
		setCurrentUrl(url);
		setOpenModalPDF(true);
	};

	const handleCloseModalPDF = () => {
		setOpenModalPDF(false);
	};

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const handleChangePageFile = (e, page) => {
		setPageNumber(page);
	};

	const handleRemoveFile = async () => {
		try {
			const data = {
				book_url: null,
			};
			await unitAPI.edit(openDeleteConfirmFile?.id, data);
			enqueueSnackbar("Success", {
				variant: "success",
				autoHideDuration: 2000,
			});
			setOpenDeleteConfirm({ id: "", open: false });
			getBook();
		} catch (error) {
			setOpenDeleteConfirm({ id: "", open: false });
			enqueueSnackbar(error?.message || "Error", {
				variant: "error",
				autoHideDuration: 2000,
			});
		}
	};

	const handleUploadImage = async (info) => {
		setIsUploadList((prevState) => [
			...prevState,
			{
				id: selectedFile?.id,
				isUpload: true,
			},
		]);
		const file = info?.file;
		const formData = new FormData();
		formData.set("file", file);
		try {
			const resFile = await fileAPI.create(formData);
			const urlRes = resFile?.results?.object?.url;

			const data = {
				book_url: urlRes,
			};
			try {
				await unitAPI.edit(selectedFile?.id, data);
				getBook();
			} catch (error) {}
			// setIsLoadFile(null);
			const newUpLoadList = isUploadList?.filter((item) => item?.id !== selectedFile?.id);
			setIsUploadList(newUpLoadList);
		} catch (error) {
			// setIsLoadFile(null);
			const newUpLoadList = isUploadList?.filter((item) => item?.id !== selectedFile?.id);
			setIsUploadList(newUpLoadList);
		}
	};

	const onSubmit = async (data) => {
		const newData = {
			...data,
			book_id: book.id,
			order: currentUnit.order,
		};

		if (isUpdate) {
			try {
				await unitAPI.edit(currentUnit.id, newData);
				setOpenModal(false);
				getBook();
			} catch (error) {}
		} else {
			try {
				await unitAPI.create(newData);
				setOpenModal(false);
				getBook();
			} catch (error) {}
		}
	};

	useEffect(() => {
		getBook();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const action = (
		<>
			<Button color="primary" size="small" onClick={() => removeUnit()}>
				Delete
			</Button>
			<Button
				color="secondary"
				size="small"
				onClick={() => setOpenDeleteConfirm({ ...openDeleteConfirm, open: false })}
			>
				Cancel
			</Button>
		</>
	);

	const action1 = (
		<>
			<Button color="primary" size="small" onClick={() => handleRemoveFile()}>
				Delete
			</Button>
			<Button
				color="secondary"
				size="small"
				onClick={() => setOpenDeleteConfirmFile({ ...openDeleteConfirmFile, open: false })}
			>
				Cancel
			</Button>
		</>
	);

	return (
		<Root>
			<MainCard>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						"& > p": {
							fontSize: "20px",
							color: "#000",
						},
					}}
				>
					<p>{book?.title}</p>
				</Box>

				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							rows={rows}
							columns={columns}
							autoHeight
							autoWidth
							disableColumnMenu
							hideFooter={true}
							rowHeight={60}
							initialState={{
								sorting: {
									sortModel: [{ field: "order", sort: "desc" }],
								},
							}}
						/>
					</Grid>
				</Grid>

				<ModalEmpty open={openModal} handleClose={handleCloseModal}>
					<Typography id="modal-modal-title" variant="h3" component="h1">
						{isUpdate ? "Update unit" : "Create unit"}
					</Typography>
					<Box
						sx={{
							"& .MuiTextField-root": {
								mt: 3,
							},
						}}
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<TextField
								name="title"
								id="outlined-basic"
								placeholder="Enter unit name"
								label="Unit name"
								variant="outlined"
								fullWidth
								autoComplete="off"
								error={errors.title}
								inputRef={register({
									required: "Unit name is required",
									maxLength: {
										value: 150,
										message: "Please enter less than 150 characters",
									},
								})}
								helperText={errors.title?.message}
								InputLabelProps={{ shrink: true }}
							/>
							<Box
								sx={{
									mt: 4,
									display: "flex",
									justifyContent: "flex-end",
								}}
							>
								<Button variant="outlined" sx={{ mr: 3 }} onClick={handleCloseModal}>
									Cancel
								</Button>
								<Button type="submit" variant="outlined">
									{isUpdate ? "Update" : "Create"}
								</Button>
							</Box>
						</form>
					</Box>
				</ModalEmpty>
			</MainCard>

			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openDeleteConfirm.open}
				onClose={() => setOpenDeleteConfirm(false)}
				message="You want to delete unit!"
				action={action}
			/>

			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openDeleteConfirmFile.open}
				onClose={() => setOpenDeleteConfirmFile(false)}
				message="You want to delete file!"
				action={action1}
			/>

			<Modal
				visible={openModalPDF}
				onCancel={handleCloseModalPDF}
				centered
				width="650px"
				footer={null}
				zIndex="10000"
			>
				<Box>
					<Document file={currentUrl} onLoadSuccess={onDocumentLoadSuccess}>
						<Page pageNumber={pageNumber} width={600} />
					</Document>
					<Box sx={{ textAlign: "center" }}>
						Page {pageNumber} of {numPages}
					</Box>
					<Pagination
						sx={{ display: "flex", justifyContent: "center", mt: 2 }}
						page={pageNumber}
						count={numPages}
						onChange={handleChangePageFile}
						color="primary"
						variant="outlined"
						shape="rounded"
					/>
				</Box>
			</Modal>
		</Root>
	);
};

export default EditUnitTT;
