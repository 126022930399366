// assets
import { IconPalette, IconShadow, IconShieldCheck, IconUser, IconWindmill } from "@tabler/icons";

// constant
const icons = {
	IconUser,
	IconPalette,
	IconShadow,
	IconWindmill,
	IconShieldCheck,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
	id: "utilities",
	title: "General",
	type: "group",
	children: [
		{
			id: "Employee",
			title: "Employee",
			type: "item",
			url: "employee",
			icon: icons.IconShieldCheck,
			breadcrumbs: false,
		},
		{
			id: "Users",
			title: "User",
			type: "item",
			url: "users",
			icon: icons.IconUser,
			breadcrumbs: true,
		},
	],
};

export default utilities;
