import { LOGIN_EMPLOYEE, LOGOUT_EMPLOYEE } from "store/actions/EmployeeAction/type";

const initState = {
	employeeInfo: JSON.parse(localStorage.getItem("employeeInfo")) || {},
};

const EmployeeReducer = (state = initState, action) => {
	switch (action.type) {
		case LOGIN_EMPLOYEE: {
			const newEmployeeInfo = action.payload;
			return {
				...state,
				employeeInfo: newEmployeeInfo,
			};
		}

		case LOGOUT_EMPLOYEE: {
			return {
				...state,
				employeeInfo: {},
			};
		}

		default:
			return state;
	}
};

export default EmployeeReducer;
