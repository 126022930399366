import dashboard from "./dashboard";
import utilities from "./utilities";
import book from "./books";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
	items: [utilities, book],
};

export default menuItems;
