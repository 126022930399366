import ReactDOM from "react-dom";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

// project imports
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { store } from "./store";

// style + assets
import "./assets/scss/style.scss";
import "antd/dist/antd.css";
import { ThemeProvider } from "@emotion/react";
import theme from "./Theme";
import { ToastProvider } from "providers/ToastProvider";
import { ModalProvider } from "providers/ModalProvider";
import { LoadingProvider } from "providers/LoadingProvider";
// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<Provider store={store}>
			<BrowserRouter>
				<ToastProvider>
					<ModalProvider>
						<LoadingProvider>
							<App />
						</LoadingProvider>
					</ModalProvider>
				</ToastProvider>
			</BrowserRouter>
		</Provider>
	</ThemeProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
