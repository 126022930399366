import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./reducers/CustomizationReducer";
import EmployeeReducer from "./reducers/EmployeeReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
	customization: customizationReducer,
	employee: EmployeeReducer,
});

export default reducer;
