import MainLayout from "layout/MainLayout";
import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loadable from "ui-component/Loadable";
import config from "../config";
// routes
import MainRoutes from "./MainRoutes";

// login option 3 routing
const AuthLogin3 = Loadable(
	lazy(() => import("views/pages/authentication/authentication3/Login3"))
);
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes({ isLoggedIn }) {
	return useRoutes(
		[
			{ path: "/", element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />, ...MainRoutes },
			{
				path: "/login",
				element: !isLoggedIn ? <AuthLogin3 /> : <Navigate to="/" />,
				exact: true,
			},
		],
		config.basename
	);
}
