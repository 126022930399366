// assets
import { IconBook, IconBookmark } from "@tabler/icons";

// constant
const icons = {
	IconBook,
	IconBookmark,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const book = {
	id: "book",
	title: "Books",
	type: "group",
	children: [
		{
			id: "Course",
			title: "Course",
			type: "item",
			url: "courses",
			icon: icons.IconBookmark,
			breadcrumbs: true,
		},
		// {
		// 	id: "Book",
		// 	title: "Book",
		// 	type: "item",
		// 	url: "books",
		// 	icon: icons.IconBook,
		// 	breadcrumbs: true,
		// },
	],
};

export default book;
