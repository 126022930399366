import axiosClient from "../axiosClient";

const authAPI = {
	async loginCMS(data) {
		const url = `auth/employee_login`;
		const resp = await axiosClient.post(url, data);
		return resp;
	},
};

export default authAPI;
