// const ICON = {
//     eye_on: require(`../assets/icons/eye-on.png`),
//     eye_off: require(`../assets/icons/eye-off.png`),
//     green_check: require(`../assets/icons/green-check.png`),
//   };

const IMAGES = {
	logo: require(`./imgs/ebook-logo.png`).default,
	avatar_default: require(`./users/avatar-default.png`).default,
	img_default: require(`./users/default-img.png`).default,
};

//   export { ICON };
export { IMAGES };
