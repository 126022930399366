import axiosClient from "api/axiosClient";

const unitAPI = {
	async getList(params) {
		const url = "unit";
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async create(data) {
		const url = "unit";
		const resp = await axiosClient.post(url, data);
		return resp;
	},

	async remove(id) {
		const url = `unit/${id}`;
		const resp = await axiosClient.delete(url);
		return resp;
	},

	async edit(id, data) {
		const url = `unit/${id}`;
		const resp = await axiosClient.put(url, data);
		return resp;
	},
};

export default unitAPI;
