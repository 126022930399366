import axiosClient from "api/axiosClient";

const fileAPI = {
	async create(data) {
		// const url = "http://localhost:9876/api/v1/file/upload";
		const url = "file/upload";
		const resp = await axiosClient.post(url, data);
		return resp;
	},
};

export default fileAPI;
