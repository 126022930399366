import axiosClient from "api/axiosClient";

const mysqlAPI = {
	async getList(url, params) {
		const resp = await axiosClient.get(url, { params });
		return resp;
	},
};

export default mysqlAPI;
