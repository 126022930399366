export const CourseList = [
	{
		id: 1,
		title: "Regular course (Primary)",
		course_type: "PRIMARY",
		grades: [1, 2, 3, 4, 5, 6],
		subject: [
			{
				id: 1,
				title: "English",
				subject_type: "ENGLISH",
			},
			{
				id: 2,
				title: "Math",
				subject_type: "MATH",
			},
			{
				id: 3,
				title: "Reasoning",
				subject_type: "REASONING",
			},
			{
				id: 4,
				title: "Literature and Problem Solving (LPS)",
				subject_type: "LPS",
			},
		],
	},
	{
		id: 2,
		title: "Regular course (Secondary)",
		course_type: "SECONDARY",
		grades: [7, 8, 9, 10, 11, 12],
		subject: [
			{
				id: 1,
				title: "English",
				subject_type: "ENGLISH",
			},
			{
				id: 2,
				title: "Mathematics",
				subject_type: "MATHEMATICS",
			},
		],
	},
	{
		id: 3,
		title: "Power Writing course",
		course_type: "PWC",
		grades: [3, 4, 5, 6],
		subject: [
			{
				id: 1,
				title: "Power Writing",
				subject_type: "PW",
			},
		],
	},
	{
		id: 4,
		title: "Trial Test course",
		course_type: "TRIAL",
		grades: [],
		subject: [],
	},
];

export const unit = [
	{
		id: 1,
		order: 1,
	},
	{
		id: 2,
		order: 2,
	},
	{
		id: 3,
		order: 3,
	},
	{
		id: 4,
		order: 4,
	},
	{
		id: 5,
		order: 5,
	},
	{
		id: 6,
		order: 6,
	},
	{
		id: 7,
		order: 7,
	},
	{
		id: 8,
		order: 8,
	},
	{
		id: 9,
		order: 9,
	},
	{
		id: 10,
		order: 10,
	},
];

export const unitY6_TT_B1_B2 = [
	{
		id: 1,
		order: 1,
	},
	{
		id: 2,
		order: 2,
	},
	{
		id: 3,
		order: 3,
	},
	{
		id: 4,
		order: 4,
	},
	{
		id: 5,
		order: 5,
	},
	{
		id: 6,
		order: 6,
	},
	{
		id: 7,
		order: 7,
	},
	{
		id: 8,
		order: 8,
	},
	{
		id: 9,
		order: 9,
	},
	{
		id: 10,
		order: 10,
	},
	{
		id: 11,
		order: 11,
	},
	{
		id: 12,
		order: 12,
	},
	{
		id: 13,
		order: 13,
	},
];

export const unitY8_TT_B1_B2 = [
	{
		id: 1,
		order: 1,
	},
	{
		id: 2,
		order: 2,
	},
	{
		id: 3,
		order: 3,
	},
	{
		id: 4,
		order: 4,
	},
	{
		id: 5,
		order: 5,
	},
	{
		id: 6,
		order: 6,
	},
	{
		id: 7,
		order: 7,
	},
	{
		id: 8,
		order: 8,
	},
	{
		id: 9,
		order: 9,
	},
	{
		id: 10,
		order: 10,
	},
	{
		id: 11,
		order: 11,
	},
	{
		id: 12,
		order: 12,
	},
	{
		id: 13,
		order: 13,
	},
	{
		id: 14,
		order: 14,
	},
	{
		id: 15,
		order: 15,
	},
];

export const Y6_TT_RC_B1_ID = "cb7d2110-8d78-11ec-a423-c746948b1582";
export const Y6_TT_RC_B2_ID = "fb971fe0-8d78-11ec-a423-c746948b1582";
export const Y8_TT_RC_B1_ID = "026e3920-8d79-11ec-a423-c746948b1582";
export const Y8_TT_RC_B2_ID = "096e1010-8d79-11ec-a423-c746948b1582";
