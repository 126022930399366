import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 500,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	borderRadius: "10px",
};

function ModalEmpty({ open, handleClose, children }) {
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>{children}</Box>
			</Modal>
		</div>
	);
}

export default ModalEmpty;
