import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { Box } from "@mui/system";

HeaderActions.propTypes = {
  title: PropTypes.string,
};

function HeaderActions(props) {
  const { title, setOpen, onCreate, setIsUpdate } = props;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}>
      <strong>
        <h2>{title}</h2>
      </strong>
      {onCreate && (
        <div>
          <Button
            onClick={() => {
              setOpen(true);
              setIsUpdate(false);
            }}
            variant="contained"
            color="primary">
            Create
          </Button>
        </div>
      )}
    </Box>
  );
}

export default HeaderActions;
