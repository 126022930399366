import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React, { createContext } from "react";

const ModalContext = createContext();
const { confirm } = Modal;

const ModalProvider = ({ children }) => {
	const showDeleteConfirm = (data, handleOk, title, okText) => {
		confirm({
			title: title || "Do you want to remove it?",
			icon: <ExclamationCircleOutlined />,
			okText: okText || "Remove",
			okType: "danger",
			cancelText: "Cancel",
			centered: true,
			zIndex: 10000,
			onOk() {
				handleOk(data);
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const value = {
		showDeleteConfirm,
	};

	return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export { ModalContext, ModalProvider };
