import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Alert, Button, Grid } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import downloadHistoryAPI from "api/DownloadHistoryAPI";
import mysqlAPI from "api/MySQLAPI";
import moment from "moment";
import { LoadingContext } from "providers/LoadingProvider";
import { ModalContext } from "providers/ModalProvider";
import queryString from "query-string";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import HeaderActions from "ui-component/Header-actions";

// ==============================|| USER ||============================== //

const TIME_DOWNLOAD = 280 * 24 * 60 * 60 * 1000; // 280 day
// const TIME_DOWNLOAD = 24 * 60 * 60 * 1000; // 1 day
// const TIME_DOWNLOAD = 10 * 60 * 1000; // 10 min
const StudentBooks = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { uid } = useParams();

	const modalContext = useContext(ModalContext);
	const { showLoading, hideLoading } = useContext(LoadingContext);

	const queryParams = useMemo(() => {
		const params = queryString.parse(location.search);

		return {
			...params,
			page: Number.parseInt(params.page) || 1,
			// search: params.search || '',
		};
	}, [location.search]);

	const [rows, setRows] = useState([]);
	const [data, setData] = useState([]);
	const [isOpen, setIsOpen] = useState({
		open: false,
		user_id: "",
	});
	const [state, setState] = useState({
		openState: false,
		vertical: "top",
		horizontal: "center",
	});
	const { vertical, horizontal, openState } = state;
	const [pagination, setPagination] = useState({
		page: 1,
		limit: 9999,
		total: 10,
	});

	const columns = [
		{
			field: "title",
			headerName: "Book Name",
			sortable: false,
			headerAlign: "center",
			align: "center",
			minWidth: 250,
			flex: 1,
			// renderCell: (params) => {
			// 	return <div>{params?.value?.title}</div>;
			// },
		},
		{
			field: "remaining_date",
			headerName: "Remaining Date",
			sortable: false,
			headerAlign: "center",
			align: "center",
			minWidth: 250,

			renderCell: (params) => {
				const remainingTime =
					moment(params.row.download_date).valueOf() - moment().utc().valueOf() + TIME_DOWNLOAD;

				let duration = moment.duration(remainingTime, "milliseconds");

				const dayRaw = params?.value / 1000 / 60 / 60 / 24;
				const days = Math.ceil(dayRaw);
				const hours = duration?._data?.hours;
				const minutes = duration?._data?.minutes;
				const seconds = duration?._data?.seconds;

				return (
					<div>
						{remainingTime > 0 && (
							<div>
								{dayRaw > 1
									? `${days} Days`
									: `${hours} Hours ${minutes} Minutes ${seconds} Seconds`}
							</div>
						)}
					</div>
				);
			},
		},
		{
			field: "status",
			headerName: "Status",
			minWidth: 120,
			editable: false,
			sortable: false,
			headerAlign: "center",
			align: "center",
		},
		{
			field: "purchase_date",
			headerName: "Purchase date",
			minWidth: 200,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => params.value && moment(params.value).format("YYYY-MM-DD HH:mm"),
		},
		{
			field: "download_date",
			headerName: "First download date",
			minWidth: 200,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => params.value && moment(params.value).format("YYYY-MM-DD HH:mm"),
		},
		{
			field: "update_date",
			headerName: "Latest download date",
			minWidth: 200,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => moment(params.value).format("YYYY-MM-DD HH:mm"),
		},
		{
			field: "allow_to_download",
			headerName: "Allow to download",
			minWidth: 150,
			sortable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				// {params?.value && <CheckCircleOutlineIcon color="success" />}

				/* <Box>
						{params?.value ? (
							<CheckCircleOutlinedIcon color="success" />
						) : (
							<Button variant="outlined" onClick={() => handleReDownload(params?.row)}>
								Re-Download
							</Button>
						)}
					</Box> */

				return params?.row?.status !== 9 && <CheckCircleOutlinedIcon color="success" />;
			},
		},
	];

	const getStudentBooks = async () => {
		showLoading();
		try {
			const url = `${process.env.REACT_APP_API_MYSQL}/student_ebook/cms`;
			const res = await mysqlAPI.getList(url, {
				fields: '["$all"]',
				filter: `{"uid": ${uid}}`,
				order: '[["purchase_date","desc"]]',
				limit: pagination.limit,
				page: queryParams.page,
			});

			const bookList = res.results.objects.rows;
			const books = bookList?.filter((item) => item?.book_info?.id);
			const paginationRes = {
				...pagination,
				page: res?.pagination?.current_page,
				total: res?.results?.objects?.count,
			};
			setRows(bookList);
			setPagination(paginationRes);
			hideLoading();
		} catch (err) {
			hideLoading();
			console.log("err: ", err);
		}
	};

	const handleClose = () => {
		setState({ ...state, openState: false });
	};

	const handleChangePage = (e, page) => {
		const filters = {
			...queryParams,
			page,
		};

		navigate({
			pathname: location.pathname,
			search: `?${queryString.stringify(filters)}`,
		});
	};

	const handleReDownload = (student_book) => {
		const download_id = student_book?.downloaded?.id;
		modalContext.showDeleteConfirm(
			download_id,
			async (id) => {
				try {
					await downloadHistoryAPI.remove(id);
					getStudentBooks();
				} catch (error) {}
			},
			"Do you want to allow this user to download?",
			"OK"
		);
	};

	useEffect(() => {
		getStudentBooks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams]);

	return (
		<>
			<MainCard>
				<HeaderActions title="Student Books" onCreate={false} />
				<Grid container spacing={gridSpacing}>
					<Grid item xs={12} sm={12}>
						<DataGrid
							getRowId={(row) => row.title}
							rows={rows}
							columns={columns}
							disableColumnMenu
							autoHeight
							autoWidth
							hideFooter={true}
							rowHeight={60}
						/>
						{/* {pagination.total !== rows?.length && (
							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									marginTop: "20px",
								}}
							>
								<Pagination
									page={pagination.page}
									count={Math.ceil(pagination.total / pagination.limit)}
									onChange={handleChangePage}
									color="primary"
									variant="outlined"
									shape="rounded"
								/>
							</Box>
						)} */}
					</Grid>
				</Grid>
			</MainCard>

			<Snackbar
				anchorOrigin={{ vertical, horizontal }}
				open={state.openState}
				autoHideDuration={1000}
				onClose={handleClose}
			>
				<Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: "100%" }}>
					Success!
				</Alert>
			</Snackbar>
		</>
	);
};

export default StudentBooks;
