import axiosClient from "api/axiosClient";

const bookAPI = {
	async getList(params) {
		const url = "/book";
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async findOne(id, params) {
		const url = `/book/${id}`;
		const resp = await axiosClient.get(url, { params });
		return resp;
	},

	async create(data) {
		const url = "/book";
		const resp = await axiosClient.post(url, data);
		return resp;
	},

	async edit(id, data) {
		const url = `/book/${id}`;
		const resp = await axiosClient.put(url, data);
		return resp;
	},

	async remove(id) {
		const url = `/book/${id}`;
		const resp = await axiosClient.delete(url);
		return resp;
	},
};

export default bookAPI;
