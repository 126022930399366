import { Link } from "react-router-dom";

// material-ui
import { Box, ButtonBase } from "@mui/material";

// project imports
import config from "../../../config";
import Logo from "../../../ui-component/Logo";
import { IMAGES } from "assets/images/imagePath";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
	<ButtonBase disableRipple component={Link} to={config.defaultPath}>
		{/* <Logo /> */}
		<img src={IMAGES.logo} width="40" alt="logo" />
	</ButtonBase>
);

export default LogoSection;
