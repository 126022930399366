import { lazy } from "react";
import { Navigate } from "react-router";
import EditUnit from "views/utilities/EditUnit";
import EditUnitTT from "views/utilities/EditUnitTT";
import StudentBooks from "views/utilities/StudentBooks";
import Loadable from "../ui-component/Loadable";

// utilities routing
const UtilsEmployee = Loadable(lazy(() => import("views/utilities/Employee")));
const UtilsUser = Loadable(lazy(() => import("views/utilities/Users")));
const UtilsCourse = Loadable(lazy(() => import("views/utilities/Course")));
const UtilsBook = Loadable(lazy(() => import("views/utilities/Books")));
const UtilsTTBook = Loadable(lazy(() => import("views/utilities/TTBooks")));
const UtilsSubject = Loadable(lazy(() => import("views/utilities/Subject")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
	children: [
		{
			path: "/",
			element: <Navigate to="/courses" />,
		},
		{
			path: "/employee",
			element: <UtilsEmployee />,
		},
		{
			path: "/users",
			element: <UtilsUser />,
		},
		{
			path: "/users/student-books/:uid",
			element: <StudentBooks />,
		},
		{
			path: "/courses",
			element: <UtilsCourse />,
		},
		{
			path: "/subjects/:course_type",
			element: <UtilsSubject />,
		},
		{
			path: "/books",
			element: <UtilsBook />,
		},
		{
			path: "/books/:course_type",
			element: <UtilsBook />,
		},
		{
			path: "/books/:course_type/:grade/:subject",
			element: <UtilsBook />,
		},
		{
			path: "/trial-books",
			element: <UtilsTTBook />,
		},
		{
			path: "/books/edit-unit/:book_id",
			element: <EditUnit />,
		},
		{
			path: "/trial-books/edit-unit/:book_id",
			element: <EditUnitTT />,
		},
	],
};

export default MainRoutes;
