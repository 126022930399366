import { Box } from "@mui/material";
import { Spin } from "antd";
import React from "react";

const LoadingComponent = ({ inline, text }) => {
	return (
		<Box
			sx={{
				backgroundColor: "rgba(52, 52, 52, 0.2)",
				position: "fixed",
				zIndex: "10000",
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
				"& > div": {
					position: "absolute",
					zIndex: "10001",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				},
			}}
		>
			<Spin size="large" tip={text || "Loading..."} />
		</Box>
	);
};

export default LoadingComponent;
